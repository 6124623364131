<template>
	<v-row>
		<v-col cols="8">
			<v-tabs v-model="tab" align-with-title>
				<!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->
				<v-tab key="agenda">
					Agenda
				</v-tab>
				<v-tab key="daftar_peserta">
					Daftar Peserta
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item key="agenda">
					<admin-pelatihan-detail-agenda></admin-pelatihan-detail-agenda>
				</v-tab-item>
				<v-tab-item key="daftar_peserta">
					<v-card>
						<v-card-title primary-title>
							Daftar Peserta <v-btn color="primary" x-small
								@click="getRegisteredPesertaPelatihan()">Reload</v-btn>
						</v-card-title>
						<v-card-text>
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th>No</th>
											<th>Email</th>
											<th>Nama</th>
											<th>No HP</th>
											<th>Status</th>
											<th>Aksi</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, key) in tablePeserta.items" :key="item.id">
											<td>{{ key + 1 }}</td>
											<td>{{ item.email }}</td>
											<td>{{ item.nama }}</td>
											<td>{{ item.hp }}</td>
											<td><v-chip v-if="item.status == 'MENDAFTAR'" color="warning" x-small>{{
												item.status }}</v-chip>
												<v-chip v-else color="success" x-small>{{ item.status }}</v-chip>
											</td>
											<td>
												<v-checkbox v-if="item.status == 'MENDAFTAR'"
													v-model="tablePeserta.selected" label="Pilih" :value="item.id"
													dense></v-checkbox>
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<td colspan="5"></td>
											<td>
												<!-- <v-checkbox class="mt-4" v-model="tablePeserta.selectedAll"
													@click="selectAll()" label="Pilih Semua"></v-checkbox> -->
												<v-btn small class="my-1" color="primary"
													:loading="tablePeserta.save_loading"
													@click="terimaPendaftarPelatihan()">Terima</v-btn>
											</td>
										</tr>
									</tfoot>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
		<v-col cols="4">
			<v-card>
				<v-card-text>
					<h2 style="text-transform: capitalize;">{{ pelatihan.nama }}</h2>
					<h3>Detail Stakeholder Pelatihan:</h3>
					<div v-html="pelatihan.detail_stakeholder"></div>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify, mdiPlus, mdiTrashCan } from '@mdi/js'
import AdminPelatihanDetailAgenda from '@/views/admin/AdminPelatihanDetail/AdminPelatihanDetailAgenda.vue'


export default {
	components: {
		AdminPelatihanDetailAgenda
	},
	data() {
		return {
			icons: {
				mdiEye,
				mdiPencil,
				mdiMagnify, mdiPlus, mdiTrashCan
			},
			id_pelatihan: this.$route.params.id,
			pelatihan: {},
			tab: null,
			tablePeserta: {
				items: [],
				selected: [],
				selectedAll: false,
				save_loading: false,
			}
		}
	},
	watch: {
	},
	computed: {
		user() {
			return this.$store.getters.user
		},
	},
	created() {
		this.getPelatihan()
		this.getRegisteredPesertaPelatihan()
	},
	methods: {
		getPelatihan() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getPelatihan', fmData)
				.then(response => {
					this.pelatihan = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		getRegisteredPesertaPelatihan() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getRegisteredPesertaPelatihan', fmData)
				.then(response => {
					this.tablePeserta.items = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		// selectAllPeserta() {
		// 	this.table2.selected = []
		// 	if (this.table2.selectedAll === true) {
		// 		this.table2.items.forEach(item => {
		// 			this.table2.selected.push(item.id)
		// 		})
		// 	} else {
		// 		this.table2.selected = []
		// 	}
		// },
		terimaPendaftarPelatihan() {
			const fmData = new FormData()
			fmData.append('aksi', 'add')
			fmData.append('id_pelatihan', this.id_pelatihan)
			fmData.append('pesertas', this.tablePeserta.selected)
			axiosPostAuth('api/Pelatihan/terimaPendaftarPelatihan', fmData)
				.then(response => {
					if (response.code === 200) {
						alert(response.message)
						this.getRegisteredPesertaPelatihan()
					}
					else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		}
	}
}
</script>