<template>
	<v-card>
		<v-card-title primary-title>
			<!-- dialog agenda pelatihan -->
			<v-dialog v-model="dialog" max-width="300px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn x-small color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="add()">
						Tambah Agenda
					</v-btn>
				</template>
				<v-card>
					<v-card-title>
						<span class="text-h5" v-if="form.editedIndex == -1">Tambah Agenda</span>
						<span class="text-h5" v-else>Edit Agenda</span>
					</v-card-title>
					<v-card-text>
						<v-text-field type="date" class="mb-2" v-model="form.editedItem.tanggal" outlined dense
							hide-details="" label="Tanggal :"></v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="save()"> Simpan </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<!-- dialog materi pelatihan -->
			<v-dialog v-model="dialogMateri" max-width="600px">
				<v-card>
					<v-card-title>
						<span class="text-h5">Form Materi</span>
					</v-card-title>
					<v-card-text>
						<v-select v-if="formMateri.editedIndex == -1" :items="formMateri.tipes"
							v-model="formMateri.editedItem.tipe" outlined dense hide-details="" class="mb-2"
							label="Tipe :"></v-select>
						<v-select v-else :items="formMateri.tipes" readonly v-model="formMateri.editedItem.tipe"
							outlined dense hide-details="" class="mb-2" label="Tipe :"></v-select>

						<v-text-field class="mb-2" v-model="formMateri.editedItem.nama" outlined dense hide-details=""
							label="Nama :"></v-text-field>
						<div v-if="formMateri.editedItem.tipe == 'MATERI' || formMateri.editedItem.tipe == 'TUGAS'">
							<a class="mb-2" v-if="formMateri.editedIndex > -1" :href="formMateri.editedItem.file_url"
								target="_blank">
								{{ formMateri.editedItem.file }} </a><br />
							<input type="file" label="Pilih File" accept="application/pdf" ref="file"
								@change="onFileChange()" class="mb-2 mt-2" />
						</div>
						<div v-if="formMateri.editedItem.tipe == 'TUGAS'">
							<v-textarea outlined hide-details="" dense label="Deskripsi :" auto-grow
								v-model="formMateri.editedItem.deskripsi" class="mb-2"></v-textarea>
							<v-select :items="['-', 'INDIVIDU', 'KELOMPOK']" v-model="formMateri.editedItem.jenis_tugas"
								outlined dense hide-details="" class="mb-2" label="Jenis Tugas :"></v-select>
						</div>
						<div
							v-if="formMateri.editedItem.tipe == 'TUGAS' || formMateri.editedItem.tipe == 'EVALUASI NARASUMBER'">
							<v-select :items="formMateri.pemateris" v-model="formMateri.editedItem.id_pemateri" outlined
								dense hide-details="" class="mb-2" label="Pemateri :" item-text="nama"
								item-value="id"></v-select>
						</div>
						<v-text-field v-if="formMateri.editedItem.tipe == 'LINK'" class="mb-2"
							v-model="formMateri.editedItem.aplikasi_url" outlined dense hide-details=""
							label="Link URL :"></v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="saveMateri()">
							<span v-if="formMateri.editedIndex > -1">Simpan Perubahan</span>
							<span v-else>Simpan</span>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card-title>
		<v-card-text>
			<router-link :to="{
				name: 'AdminPelatihanPreTest',
				params: { id_pelatihan: id_pelatihan },
			}">
				<v-btn block color="primary" class="mb-4" dark>Pre Test</v-btn>
			</router-link>
			<ul>
				<li v-for="(item, index) in table.items" :key="item.id">
					<b>Hari ke {{ index + 1 }}, {{ formatDate(item.tanggal) }}</b>
					<v-btn title="Lihat" x-small color="primary" @click="editData(item)" class="ml-2">
						<v-icon x-small>
							{{ icons.mdiPencil }}
						</v-icon>
					</v-btn>
					<v-btn title="Lihat" x-small color="error" @click="deleteData(item)" class="ml-1">
						<v-icon x-small>
							{{ icons.mdiTrashCan }}
						</v-icon>
					</v-btn>
					<v-btn title="Lihat" x-small color="success" @click="addMateri(item.id)" class="ml-1">
						<v-icon x-small>
							{{ icons.mdiPlus }}
						</v-icon>
						Materi
					</v-btn>
					<v-simple-table dense>
						<template v-slot:default>
							<tbody>
								<tr v-for="(itemMateri, indexMateri) in item.materis" :key="itemMateri.id">
									<td>
										<v-chip v-if="itemMateri.tipe == 'MATERI'" x-small color="primary">{{
											itemMateri.tipe }}</v-chip>
										<v-chip v-else-if="itemMateri.tipe == 'TUGAS'" x-small color="warning">{{
											itemMateri.tipe }}</v-chip>
										<v-chip
											v-else-if="itemMateri.tipe == 'EVALUASI NARASUMBER' || itemMateri.tipe == 'EVALUASI PENYELENGGARA'"
											x-small color="success">{{
												itemMateri.tipe }}</v-chip>
										<v-chip v-else-if="itemMateri.tipe == 'LINK'" x-small color="info">{{
											itemMateri.tipe }}</v-chip>
										{{ itemMateri.nama }}
									</td>
									<td>
										<a v-if="itemMateri.tipe == 'MATERI'" :href="itemMateri.file_url" class="mr-2"
											target="_blank">
											Lihat
										</a>
										<router-link v-else-if="itemMateri.tipe == 'TUGAS'" class="mr-2" :to="{
											name: 'AdminPelatihanTugas',
											params: { id_pelatihan: id_pelatihan, id_materi: itemMateri.id, hari_ke: index + 1 },
										}">
											Lihat
										</router-link>
										<router-link
											v-else-if="itemMateri.tipe == 'EVALUASI NARASUMBER' || itemMateri.tipe == 'EVALUASI PENYELENGGARA'"
											class="mr-2" :to="{
												name: 'AdminPelatihanEvaluasi',
												params: { id_pelatihan: id_pelatihan, id_materi: itemMateri.id, hari_ke: index + 1 },
											}">
											Lihat
										</router-link>
										<a v-if="itemMateri.tipe == 'LINK'"
											:href="itemMateri.aplikasi_url" class="mr-2" target="_blank">
											Lihat
										</a>
										<v-icon small @click="editMateri(indexMateri, itemMateri)">
											{{ icons.mdiPencil }}
										</v-icon>
										<v-icon class="ml-1" small @click="hapusMateri(itemMateri)">
											{{ icons.mdiTrashCan }}
										</v-icon>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</li>
			</ul>
			<router-link :to="{
				name: 'AdminPelatihanPostTest',
				params: { id_pelatihan: id_pelatihan },
			}">
				<v-btn block color="primary" class="mt-4" dark>Post Test</v-btn>
			</router-link>
		</v-card-text>
	</v-card>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify, mdiPlus, mdiTrashCan } from '@mdi/js'

export default {
	components: {},
	data() {
		return {
			icons: {
				mdiEye,
				mdiPencil,
				mdiMagnify,
				mdiPlus,
				mdiTrashCan,
			},
			id_pelatihan: this.$route.params.id,
			pelatihan: {},
			tableOptions: {},
			table: {
				items: [],
			},
			dialog: false,
			dialogMateri: false,
			form: {
				editedIndex: -1,
				editedItem: {
					tanggal: '',
				},
				defaultItem: {
					tanggal: '',
				},
			},
			formMateri: {
				tipes: ['MATERI', 'TUGAS', 'EVALUASI NARASUMBER', 'EVALUASI PENYELENGGARA', 'LINK'],
				editedIndex: -1,
				file_temp: '',
				pemateris: [],
				editedItem: {
					id: '',
					id_agenda: '',
					tipe: '',
					nama: '',
					file_url: '',
					file: '',
					deskripsi: '',
					jenis_tugas: '',
					id_pemateri: '',
					aplikasi_url: '',
				},
				defaultItem: {
					id: '',
					id_agenda: '',
					tipe: '',
					nama: '',
					file_url: '',
					file: '',
					deskripsi: '',
					jenis_tugas: '',
					id_pemateri: '',
					aplikasi_url: '',
				},
			},
		}
	},
	computed: {
		tipeMateri() {
			return this.formMateri.editedItem.tipe;
		}
	},
	watch: {
		tipeMateri: function (newQuestion, oldQuestion) {
			if (this.formMateri.editedIndex == -1) {
				this.formMateri.editedItem.deskripsi = ''
				this.formMateri.editedItem.jenis_tugas = ''
				this.formMateri.editedItem.id_pemateri = ''
				// this.$refs.file.value = null;
				this.formMateri.file_temp = ''
				this.formMateri.editedItem.aplikasi_url = ''
			}
		}
	},
	created() {
		this.getAgendas()
		this.getPemateris()
	},
	methods: {
		onFileChange() {
			this.formMateri.file_temp = this.$refs.file.files.item(0)
			// this.form.editedItem.previewImage = URL.createObjectURL(this.form.editedItem.foto)
		},
		save() {
			if (this.form.editedIndex > -1) {
				const fmData = new FormData()
				fmData.append('aksi', 'update')
				fmData.append('tanggal', this.form.editedItem.tanggal)
				fmData.append('id_pelatihan', this.id_pelatihan)
				fmData.append('id', this.form.editedItem.id)
				axiosPostAuth('api/Pelatihan/addAgenda', fmData)
					.then(response => {
						if (response.code === 200) {
							alert(response.message)
							this.dialog = false
							this.getAgendas()
						} else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			} else {
				const fmData = new FormData()
				fmData.append('aksi', 'add')
				fmData.append('tanggal', this.form.editedItem.tanggal)
				fmData.append('id_pelatihan', this.id_pelatihan)
				axiosPostAuth('api/Pelatihan/addAgenda', fmData)
					.then(response => {
						if (response.code === 200) {
							this.getAgendas()
						} else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		getRegisteredPesertaPelatihan() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getRegisteredPesertaPelatihan', fmData)
				.then(response => {
					this.tablePeserta.items = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		getAgendas() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getAgendas', fmData)
				.then(response => {
					this.table.items = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		getPemateris() {
			axiosGetAuth('api/Pelatihan/getPemateris')
				.then(response => {
					this.formMateri.pemateris = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		add() {
			this.form.editedItem = Object.assign({}, this.form.defaultItem)
			this.form.editedIndex = -1
		},
		editData(item) {
			this.form.editedIndex = this.table.items.indexOf(item)
			this.form.editedItem = Object.assign({}, item)
			this.dialog = true
		},
		deleteData(item) {
			if (confirm('Apa yakin akan dihapus?')) {
				const fmData = new FormData()
				fmData.append('id_agenda', item.id)
				axiosPostAuth('api/Pelatihan/deleteAgenda', fmData)
					.then(response => {
						if (response.code === 200) {
							alert(response.message)
							this.getAgendas()
						} else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		addMateri(id_agenda) {
			this.formMateri.editedItem = Object.assign({}, this.formMateri.defaultItem)
			this.formMateri.editedIndex = -1
			this.formMateri.editedItem.id_agenda = id_agenda
			this.formMateri.file_temp = ''
			this.dialogMateri = true
		},
		editMateri(index, materi) {
			this.formMateri.editedIndex = index
			this.formMateri.editedItem = Object.assign({}, materi)
			this.dialogMateri = true
		},
		hapusMateri(materi) {
			if (confirm('Apa yakin akan dihapus?')) {
				const fmData = new FormData()
				fmData.append('id_materi', materi.id)
				fmData.append('tipe', materi.tipe)
				fmData.append('file', materi.file)
				axiosPostAuth('api/Pelatihan/deleteMateri', fmData)
					.then(response => {
						if (response.code === 200) {
							alert(response.message)
							this.getAgendas()
						} else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		saveMateri() {
			if (this.formMateri.editedIndex > -1) {
				const fmData = new FormData()
				fmData.append('aksi', 'update')
				fmData.append('id', this.formMateri.editedItem.id)
				fmData.append('id_agenda', this.formMateri.editedItem.id_agenda)
				fmData.append('tipe', this.formMateri.editedItem.tipe)
				fmData.append('nama', this.formMateri.editedItem.nama)
				fmData.append('file_lama', this.formMateri.editedItem.file)
				fmData.append('deskripsi', this.formMateri.editedItem.deskripsi)
				fmData.append('jenis_tugas', this.formMateri.editedItem.jenis_tugas)
				fmData.append('id_pemateri', this.formMateri.editedItem.id_pemateri)
				fmData.append('aplikasi_url', this.formMateri.editedItem.aplikasi_url)
				if (this.formMateri.file_temp != '' && this.formMateri.file_temp != undefined) {
					fmData.append('file', this.formMateri.file_temp)
				}
				axiosPostAuth('api/Pelatihan/addMateri', fmData)
					.then(response => {
						if (response.code === 200) {
							alert(response.message)
							this.dialogMateri = false
							this.getAgendas()
						} else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			} else {
				const fmData = new FormData()
				fmData.append('aksi', 'add')
				fmData.append('id_agenda', this.formMateri.editedItem.id_agenda)
				fmData.append('tipe', this.formMateri.editedItem.tipe)
				fmData.append('nama', this.formMateri.editedItem.nama)
				fmData.append('file', this.formMateri.file_temp)
				fmData.append('deskripsi', this.formMateri.editedItem.deskripsi)
				fmData.append('jenis_tugas', this.formMateri.editedItem.jenis_tugas)
				fmData.append('id_pemateri', this.formMateri.editedItem.id_pemateri)
				fmData.append('aplikasi_url', this.formMateri.editedItem.aplikasi_url)
				axiosPostAuth('api/Pelatihan/addMateri', fmData)
					.then(response => {
						if (response.code === 200) {
							alert(response.message)
							this.getAgendas()
						} else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
	},
}
</script>
